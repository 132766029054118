import React, { useState } from 'react';
import '../../css/ColorfullCreations.css';
import Nav from '../../componet/Nav'

const ColorfullCreations = () => {
  const images=[
    {
      id:1,
      Thumb:'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjb3Bu5UwfBiXX1ddSFBX8I5FB6jxxPWzpPhYsDns_DU0y_QELzdCDp7TU8Rdjnya5_EOSA_ytU2tUBqb53EeKIdKXfzBvSKaqWhhvjYFiWLL1e1huPC1fNLenvGTBkVNHBipRsp5nQJrTewaQJ6GVR1MrgHTZXjiexMFRgeqW_9ovr0mK9_pLfX8ky/s1920/20220917214312_IMG_9074.JPG',
      imgSrc:'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjb3Bu5UwfBiXX1ddSFBX8I5FB6jxxPWzpPhYsDns_DU0y_QELzdCDp7TU8Rdjnya5_EOSA_ytU2tUBqb53EeKIdKXfzBvSKaqWhhvjYFiWLL1e1huPC1fNLenvGTBkVNHBipRsp5nQJrTewaQJ6GVR1MrgHTZXjiexMFRgeqW_9ovr0mK9_pLfX8ky/s1920/20220917214312_IMG_9074.JPG',
      ImgName:'childen Certificate',
    },
 

  ]
  return (
    <>
    <Nav/>
    <div className='gallery-headings'>
      <h1>ColorfullCreations</h1>
    </div>
  <div class="gallery">
    {images.map((img)=>(

      <a href={img.Thumb} data-lightbox="mygallery" data-title={img.ImgName} key={img.id}>
      <img src={img.imgSrc} alt={img.ImgName}/>
    </a>

    ))
}
    </div>

    </>
  );
};

export default ColorfullCreations;
