
import React from 'react';
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import '../css/Workshop.css'; // Import your CSS file for styling


const Gallery = () => {
    const Gallery = [
        { id: 1, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Kids' Art Showcase 2023" },
        { id: 2, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Young Artists' Exhibition" },
        { id: 3, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Creative Minds Gallery" },
        { id: 4, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Junior Art Stars" },
        { id: 5, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Little Picassos" },
        { id: 6, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Artistic Adventures" },
        { id: 7, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Colorful Creations" },
        { id: 8, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Imagination Station" },
        { id: 9, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Mini Masterpieces" },
        { id: 10, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Kids' Creativity Awards" },
        { id: 11, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Artistic Achievements" },
        { id: 12, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Budding Artists Gallery" },
        { id: 13, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Art Explorers" },
        { id: 14, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Childhood Creativity" },
        { id: 15, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Artistic Journeys" },
        { id: 16, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Dreams in Color" },
        { id: 17, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Young Visionaries" },
        { id: 18, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Creative Kidz Gallery" },
        { id: 19, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Artistic Futures" },
        { id: 20, img: "https://c8.alamy.com/comp/2W9JYMY/young-multiracial-winners-of-a-childrens-computerized-home-design-contest-pose-with-their-winners-certificates-and-adult-sponsors-in-costa-mesa-ca-2W9JYMY.jpg", name: "Inspiring Young Artists" },
    ];
    

  return (
    <>
    <Nav/>
            {/* -------------------------------- */}
            <div className='Workshop-container'>
                <div className='workshop'>
                    <div className='heading'>
                        <h1>Gallery</h1>
                    </div>
                    <div className='course-container'>
                        {Gallery.map((data) => (
                            <div className='List' key={data.id}> {/* Added a key prop */}
                                <div className='img'>
                                    <img src={data.img} alt={data.name} /> {/* Use data.img and data.name */}
                                </div>
                                <div className='name'>
                                    <h1>{data.name}</h1> {/* Use data.name */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* -------------------------------- */}
            <Footer />
    </>
  )
}
export default Gallery

