import React from "react";
import "../css/footer.css";
import Logo from "../assets/logo/logo.jpeg";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={Logo} alt="Logo" className="footer-logo" />
        <p className="footer-text">Learn to draw with passion and creativity!</p>
        <div className="footer-links">
          <a href="#about" className="footer-link">About Us</a>
          <a href="#courses" className="footer-link">Courses</a>
          <a href="#contact" className="footer-link">Contact</a>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-copyright">© 2024 Kaarunya Bharathi Arts. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
