import React from 'react'
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import ContentInfo from '../componet/ContentInfo'

const Contact = () => {
  return (
    <>
    <Nav/>
    <ContentInfo/>
    <Footer/>
    </>
  )
}

export default Contact