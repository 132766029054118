// src/components/NotFound.js
import React from 'react';
import '../css/error.css'; // Import the CSS file

const Error = () => {
    return (
        <div className="not-found">
            <h1>404</h1>
            <p>Oops! The page you are looking for does not exist.</p>
            <a href="/">Go back to Home</a>
        </div>
    );
};

export default Error;
