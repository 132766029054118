import React, { useEffect, useState, useRef } from 'react';
import '../css/IntutionCount.css';
import 'aos/dist/aos.css';
import AOS from 'aos';

const IntutionCount = () => {
  const [studentsLearning, setStudentsLearning] = useState(0);
  const [studentsCertified, setStudentsCertified] = useState(0);
  const [expertInstructors, setExpertInstructors] = useState(0);
  const sectionRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const countUp = (setter, target) => {
      let count = 0;
      const interval = setInterval(() => {
        if (count < target) {
          count++;
          setter(count);
        } else {
          clearInterval(interval);
        }
      }, 10); // Adjust the speed of counting here
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasAnimated) {
        setHasAnimated(true);
        countUp(setStudentsLearning, 200);
        countUp(setStudentsCertified, 300);
        countUp(setExpertInstructors, 5); // Update this number as needed
      }
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasAnimated]);

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <section ref={sectionRef}>
      <div className="hightlight_container" data-aos="fade-up"
     data-aos-duration="3000">
        <div className="hightlight_section">
          <div className="icon"><i className="bi bi-palette-fill"></i></div>
          <div className="highlight">
            <div className="head"><h1>{studentsLearning}+ Students Currently Learning</h1></div>
            <div className="content"><p>Join our vibrant community of aspiring artists and learn from the best.</p></div>
          </div>
        </div>
        <div className="hightlight_section">
          <div className="icon"><i className="bi bi-mortarboard-fill"></i></div>
          <div className="highlight">
            <div className="head"><h1>{studentsCertified}+ Students Certified</h1></div>
            <div className="content"><p>Our comprehensive courses have helped over 300 students achieve certification in various drawing techniques.</p></div>
          </div>
        </div>
        <div className="hightlight_section">
          <div className="icon"><i className="bi bi-patch-check-fill"></i></div>
          <div className="highlight">
            <div className="head"><h1>Expert Instructors</h1></div>
            <div className="content"><p>Our experienced artists are dedicated to guiding you every step of the way.</p></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntutionCount;
