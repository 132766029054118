import React from 'react'
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import Slider from '../componet/Slider'
import WhyUseUs from '../componet/WhyUseUs'
import IntutionCount from '../componet/IntutionCount'
import TeachingMethods from '../componet/TeachingMethods'
import Address from '../componet/Address'
import Feedback from '../componet/Feedback'


const Index = () => {
  return (
    <div>
      <Nav/>
      <Slider/>
      <TeachingMethods/>
      <WhyUseUs/>
      <IntutionCount/>
      <Address/>
      <Feedback/>
      <Footer/>
    </div>
  )
}

export default Index