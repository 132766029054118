import React from 'react';
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import '../css/Workshop.css'; // Import your CSS file for styling

const Course = () => {
    const Course = [
        {
            id: 1,
            img: "https://miro.medium.com/v2/resize:fit:1400/1*yQRALIvexGrSwB99C1Td5Q.png",
            name: "Pencil Drawing"
        },
        {
            id: 1,
            img: "https://miro.medium.com/v2/resize:fit:1400/1*yQRALIvexGrSwB99C1Td5Q.png",
            name: "Pencil Drawing"
        },
    ];

    return (
        <>
            <Nav />
            {/* -------------------------------- */}
            <div className='Workshop-container'>
                <div className='workshop'>
                    <div className='heading'>
                        <h1>Available Course</h1>
                    </div>
                    <div className='course-container'>
                        {Course.map((data) => (
                            <div className='List' key={data.id}> {/* Added a key prop */}
                                <div className='img'>
                                    <img src={data.img} alt={data.name} /> {/* Use data.img and data.name */}
                                </div>
                                <div className='name'>
                                    <h1>{data.name}</h1> {/* Use data.name */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* -------------------------------- */}
            <Footer />
        </>
    );
};

export default Course;
