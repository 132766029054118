import React, { useEffect } from 'react'
import '../css/Address.css'
import location from '../assets/icons/location.png'
import 'aos/dist/aos.css';
import AOS from 'aos';

const Address = () => {
        useEffect(() => {
                AOS.init();
              }, []);
            
  return (
    <div className='Address-container' data-aos="fade-up"
    data-aos-duration="3000">
        <div className='head'>
            <img src={location} alt='location in kaarunyabharathi'/>Available Location's</div>
            <div className='Address'>
                
                <div className='list'>
                        <p>2/780, MM Nagar, Amirtha Nagar, Hosur, Tamil Nadu 635 109.</p>
                </div>
                
                <div className='list'>
                        <p>Kaarunya bharathi arts, Vikashnagar, 7th St, Sm nagar, Hosur, Tamil Nadu 635 126.</p>
                </div>
                
                <div className='list'>
                        <p>Amazing Kids, Manikavasagar street, MGR Nagar, Bharathi Nagar, Hosur, Tamil Nadu 635 126.</p>
                </div>

                <div className='list'>
                        <p>Plot no-03 , Rotary club ,Mathigiri kootu road,Old Mathigiri , Hosur - 635 110.</p>
                </div>
            </div>
    </div>
  )
}

export default Address