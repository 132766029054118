import React, { useState } from 'react';
import '../css/nav.css';
import logo from '../assets/logo/logo.jpeg';
import { NavLink } from 'react-router-dom';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <>
      <nav>
        <div className='nav-container'>
          <div className='left'>
            <div className='img'>
              <img src={logo} alt='' />
            </div>
          </div>
          <input type='checkbox' id='menubar_controller' checked={isOpen} readOnly />
          <div className='right'>
            <div className='menubar'>
              <div className='menu-icon' onClick={toggleMenu}>
                <div className='line'></div>
                <div className='line'></div>
                <div className='line'></div>
              </div>
              <div className='menubar-overlay' onClick={() => setIsOpen(false)}></div>
              <div className={`menus ${isOpen ? 'open' : ''}`}>
                <ul>
                  <li className={window.location.pathname === '/' ? 'active' : ''}>
                    <NavLink to="/" exact>Home</NavLink>
                  </li>
                  <li className={window.location.pathname === '/course' ? 'active' : ''}>
                    <NavLink to="/course">course</NavLink>
                  </li>
                  <li className={window.location.pathname === '/WorkShop' ? 'active' : ''}>
                    <NavLink to="/WorkShop">WorkShop</NavLink>
                  </li>
                  <li className={window.location.pathname === '/about' ? 'active' : ''}>
                    <NavLink to="/about">About us</NavLink>
                  </li>
                  <li className={window.location.pathname === '/contact' ? 'active' : ''}>
                    <NavLink to="/contact">Contact us</NavLink>
                  </li>
                  <li className={window.location.pathname === '/gallery' ? 'active' : ''}>
                    <NavLink to="/gallery">Gallery</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
