import React from 'react'
import '../css/contact.css';

const ContentInfo = () => {
  return (
    <>
    <main>
        <div class="contact_page"><h1>Contect Us</h1></div>
        <div class="contact-container">
            <div class="address">
                {/* <!-- Address --> */}
                <div class="items">
                    <div class="icon">
                        <i class="bi bi-geo-alt-fill"></i>
                    </div>
                <div class="links">
                    <div class="head">
                        <h1>Address</h1>
                    </div>
                    <div class="main_link">
                        <p>Plot no: 3, Rotary club, mathigiri kootu road, hosur.</p>
                    </div>
                </div>
            </div>
            {/* <!-- contact number --> */}
            <div class="items">
                <div class="icon"><i class="bi bi-telephone-fill"></i></div>
                <div class="links">
                    <div class="head">
                        <h1>Phone No.</h1>
                    </div>
                    <div class="main_link">
                        <p>+91 99942 81962</p>
                        <p>+91 90251 57220</p>
                    </div>
                </div>
            </div>
            {/* <!-- gmail id --> */}
            <div class="items">
                <div class="icon"><i class="bi bi-envelope-fill"></i></div>
                <div class="links">
                    <div class="head">
                        <h1>Write to us</h1>
                    </div>
                    <div class="main_link">
                        <p>vmkaarunyabharathiarts@gmail.com</p>
                    </div>
                </div>
            </div>
            {/* <!-- social media link --> */}
            <div class="items">
                <div class="icon"><i class="bi bi-link-45deg"></i></div>
                <div class="links">
                    <div class="head">
                        <h1>Social Links</h1>
                    </div>
                    <div class="main_link">
                        <p><b>Facebook:</b> <a href="#">Kaarunyabharathiarts</a></p>
                        <p><b>Instagram:</b> <a href="#">Kaarunyabharathiarts</a></p>
                        <p><b>Youtube:</b><a href="#">Kaarunyabharathiarts</a></p>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="map">
            <div class="view"><iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3892.1399741780606!2d77.80697647454265!3d12.70428572068374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae715a606d0723%3A0xb00922b94c92de36!2sKaarunya%20bharathi%20arts!5e0!3m2!1sen!2sin!4v1721960320248!5m2!1sen!2sin"
        width="100%"
        height="300px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps"
      ></iframe></div>
        </div>
     </main>
    </>
  )
}

export default ContentInfo