import React from 'react';
import ReactDOM from 'react-dom';
import './css/reset.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import pages
import Home from './page/Home';
import Error from './page/Error';
import Contact from './page/Contact';
import Workshop from './page/Workshop';
import Course from './page/Course';
import Gallery from './page/Gallery';
import ColorfullCreations from './page/gallery/ColorfullCreations';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/course" element={<Course />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/colorfullcreations" element={<ColorfullCreations />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Performance measuring (optional)
reportWebVitals();
