import React, { useState, useEffect } from 'react';
import "../css/Feedback.css";
import star_fill from "../assets/icons/start-fill.svg";
import star_off_fill from "../assets/icons/start-off-fill.svg";
import star_border from "../assets/icons/start-border.svg";

const Feedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const feedBackInfo = [
    {
      img: "https://www.wallsnapy.com/img_gallery/recent-thalapathy-vijay-hd-images-wallpaper-2607478.jpg",
      name: 'Vijay',
      occupation: "Actor",
      message: "I’ve always believed in nurturing talent, and Runkaarnuya Bharathi's drawing class does just that! The instructor’s passion and dedication create an inspiring environment. It's a great place for artists to flourish!",
      rate: 4.5,
    },
    {
      img: "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3bec1e63-7b70-4349-a0d4-0ab7df99bd81/width=450/01158.jpeg",
      name: 'Sai Pallavi',
      occupation: "Actress",
      message: "Art is a beautiful form of expression, and this class truly captures that essence! The supportive environment at Runkaarnuya Bharathi’s drawing class helped me connect with my creative side. Highly recommended!.",
      rate: 5,
    },
    {
      img: "https://1847884116.rsc.cdn77.org/tamil/gallery/actress/priyabhavani/priyabhavani280622_6.jpg",
      name: 'Priya Bhavani Shankar',
      occupation: "Actress",
      message: "Joining this drawing class has been a rewarding experience! Runkaarnuya Bharathi’s approach is both nurturing and challenging. It’s a perfect blend of skill-building and creativity. I’m loving every moment!",
      rate:4.5,
    },
    {
      img: "https://assets.thehansindia.com/h-upload/2023/01/19/1330611-bcs0pp7ynhoxjvhobbloojruuys.webp",
      name: 'Ajith',
      occupation: "Actor",
      message: "As someone who appreciates creativity, I found Runkaarnuya Bharathi's drawing class to be an incredible journey. The atmosphere is electric, and the guidance is top-notch. Highly recommend it for anyone looking to express themselves through art!.",
      rate: 4.5,
    },
    {
      img: "https://img.studioflicks.com/wp-content/uploads/2021/08/16174130/Santhanam.jpg",
      name: 'Santhanam',
      occupation: "Actor",
      message: "Runkaarnuya Bharathi's drawing class is a delightful experience! The fun and engaging sessions bring out the artist in you. I appreciate the humor and creativity woven into every lesson!",
      rate: 5,
    },
    {
      img: "https://m.media-amazon.com/images/M/MV5BOTFlMmI3OTUtZTBkMi00ZDBhLTkzYTYtZmNkZDI4YjEzYmYzXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg",
      name: 'Vadivel',
      occupation: "Actor",
      message: "What a fantastic class! Runkaarnuya Bharathi has a way of making art enjoyable and accessible to everyone. I’ve laughed, learned, and improved my skills all at once. A must-join for aspiring artists",
      rate: 4,
    },
  ];

  // Function to get the star rating
  const getStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 6; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<img key={i} src={star_fill} alt="" />);
      } else if (i === Math.floor(rating) && rating % 1 !== 0) {
        stars.push(<img key={i} src={star_off_fill} alt="" />);
      } else {
        stars.push(<img key={i} src={star_border} alt="" />);
      }
    }
    return stars.slice(0, 5);
  };

  // Effect to change the current index every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % feedBackInfo.length);
    }, 2000); // Change every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [feedBackInfo.length]);

  return (
    <div className='trustedourclient-container'>
      <div className='trustedour'>
        <div className='left'>
          <h1>FeedBacks</h1>
        </div>
        <div className='right'>
          {feedBackInfo.map((info, index) => (
            <div className={`list ${index === currentIndex ? 'active' : ''}`} key={index} style={{ display: index === currentIndex ? 'block' : 'none' }}>
              <div className='profile-name'>
                <div className='user-name'>
                  <div className='left'>
                    <div className='img'>
                      <img src={info.img} alt={info.name}/>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='user_name'>{info.name}</div>
                    <div className='occupation'>{info.occupation}</div>
                  </div>
                </div>
                <div className='rate'>
                  {getStarRating(info.rate)}
                </div>
              </div>
              <div className='message'>
                <p>{info.message}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
