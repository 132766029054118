import React, { useEffect } from 'react'
import '../css/whyuseus.css'
import 'aos/dist/aos.css';
import AOS from 'aos';
// icons
import Instructer from '../assets/icons/coach.png'
import Price from '../assets/icons/money.png'
import Growth from '../assets/icons/growth.png'

const WhyUseUs = () => {
    
    useEffect(() => {
        AOS.init();
      }, []);
    
  return (
    <div className='whyuseus-container'>
        <div className='heading'>
            <h1>Why Choose Us</h1>
        </div>
<div className='whyuseus'>
<div data-aos="fade-right"  className='section'>
    <div className='img'>
        <img src={Instructer} alt='icon'/>
    </div>
    <div className='head'>
        <h1>Expert Instructors</h1>
    </div>
    <div className='contant'>
        <p>Our experienced instructors are passionate artists dedicated to nurturing creativity and talent. They provide personalized guidance to help every student excel.</p>
    </div>
</div>

<div className='section' data-aos="flip-left">
    <div className='img'>
        <img src={Price} alt='icon'/>
    </div>
    <div className='head'>
        <h1>Affordable Classes</h1>
    </div>
    <div className='contant'>
        <p>We offer competitive pricing and flexible payment options to make quality art education accessible to everyone. Investing in your child's creativity shouldn't break the bank.</p>
    </div>
</div>

<div className='section' data-aos="fade-left">
    <div className='img'>
        <img src={Growth} alt='icon'/>
    </div>
    <div className='head'>
        <h1>Focus on Personal Growth</h1>
    </div>
    <div className='contant'>
        <p>Beyond art skills, we emphasize personal development. Our classes boost confidence, enhance problem-solving abilities, and encourage critical thinking. </p>
    </div>
</div>
</div>
    </div>
  )
}

export default WhyUseUs